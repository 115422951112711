
import Api from "@/lib/api";
import Vue, { PropType } from "vue";
import { Rq, Table } from "@/lib/amdt";
import VForm from "@/lib/types/v-form";
import WorgError from "@/lib/worg-error";
import {
  arrayRequired,
  required,
  maxLength,
  cpf,
  cnpj,
} from "@/lib/validations";
import IPerson from "@/lib/interfaces/person";
import vAlertMessage from "@/components/layout/v-alert-message.vue";
import ABtnDialogConfirm from "@/components/elements/a-btn-dialog-confirm.vue";
import ATextField from "@/components/elements/a-text-field.vue";
import ABtn from "@/components/elements/a-btn.vue";
import ASwitch from "@/components/elements/a-switch.vue";
import ASelect from "@/components/elements/a-select.vue";
import ACard from "@/components/elements/a-card.vue";
import ATextFieldCpf from "@/components/elements/a-text-field-cpf.vue";
import ATextFieldCnpj from "@/components/elements/a-text-field-cnpj.vue";
import PersonContactsList from "./maintenance/person-contacts-list.vue";
import PersonAddressesList from "./maintenance/person-addresses-list.vue";
import PersonContact from "./maintenance/person-contact.vue";
import PersonAddress from "./maintenance/person-address.vue";
import AAutocomplete from "@/components/elements/a-autocomplete.vue";
import ITransactioner from "@/lib/interfaces/transactioner";
import IPersonTransactioner from "@/lib/interfaces/person-transactioner";
import IPersonVinculos from "@/lib/interfaces/person-vinculos";
import { numberPadding } from "@/lib/util";

export default Vue.extend({
  name: "PeopleMaintenance",

  components: {
    vAlertMessage,
    ABtnDialogConfirm,
    ATextField,
    ABtn,
    ASwitch,
    ASelect,
    ACard,
    ATextFieldCpf,
    ATextFieldCnpj,
    PersonContactsList,
    PersonAddressesList,
    PersonContact,
    PersonAddress,
    AAutocomplete,
  },

  props: {
    person: {
      type: Object as PropType<IPerson>,
      required: true,
    },
  },

  data() {
    return {
      selectSetor: "",

      loading: false,
      valid: false,
      ret_id: 1,
      ret_msg: "",

      personContactDialog: false,
      personAddressDialog: false,

      forceRefreshContacts: 0,
      forceRefreshAddresses: 0,

      transactioners: new Array<ITransactioner>(),
      personTransactioners: new Array<IPersonTransactioner>(),
      personVinculos: new Array<IPersonVinculos>(),
      updatedTransactioners: new Array<string>(),

      updatedRegions: [] as Array<string>,
      sendRegionsVinculos: [] as Array<string>,

      dataPerson: {
        id: "",
        referente_id: "default",
        empresa_id: "default",
        nome_raz: "",
        apelido_fant: "",
        cpf_cnpj: "",
        doc_ie: "",
        tipo: "",
        inf_adicional: "",
        data_criacao: "default",
        desativado: "0",
      } as IPerson,

      PESSOA_FISICA: "1",
      PESSOA_JURIDICA: "2",
      pessoaTipos: [
        { value: "", text: "Escolha o tipo da pessoa" },
        { value: "1", text: "Pessoa Física" },
        { value: "2", text: "Pessoa Jurídica" },
      ],
    };
  },

  watch: {
    person: {
      deep: true,
      handler() {
        this.ret_id = 1;
        this.ret_msg = "";
        this.dataPerson = {
          ...this.person,
        };
        this.getPersonTransactioners().then();
        this.getPersonVinculos().then();
      },
    },

    personTransactioners() {
      this.updatedTransactioners = this.personTransactioners.map(
        (item) => item.transacionador_id
      );
    },
    updatedRegions: {
      handler(newUpdatedRegions: string[]) {
        this.sendRegionsVinculos = newUpdatedRegions
          .map((region) => {
            const [id_usuario_empresa] = region.split("-");
            const foundRegion = this.regions.find(
              (r) => r.id_usuario_empresa === id_usuario_empresa
            );
            return foundRegion
              ? `${foundRegion.empresa_id}-${foundRegion.id_setor}`
              : null;
          })
          .filter((region): region is string => region !== null); // Remove nulos
      },
      immediate: true, // Garante que seja chamado ao montar o componente
    },
  },

  computed: {
    form(): VForm {
      return this.$refs.form as VForm;
    },

    cTextSave(): string {
      return this.dataPerson.id ? "Alterar" : "Salvar";
    },

    cNomeLabel(): string {
      const tipo = this.dataPerson.tipo as "1" | "2";

      return (
        {
          "1": "Nome",
          "2": "Razão social",
        }[tipo] || "Nome ou razão social"
      );
    },

    cNomeFantasiaLabel(): string {
      const tipo = this.dataPerson.tipo as "1" | "2";

      return (
        {
          "1": "Apelido",
          "2": "Nome fantasia",
        }[tipo] || "Apelido ou nome fantasia"
      );
    },

    personId(): string {
      return this.dataPerson.id;
    },
    regions(): {
      id_usuario_empresa: string;
      empresa_id: string;
      regiao_nome: string;
      nome_setor: string;
      id_setor: string;
      text_regiao: string;
      text_setor: string;
    }[] {
      const regions = this.$store.state.regions as {
        id_usuario_empresa: string;
        empresa_id: string;
        regiao_nome: string;
        nome_setor: string;
        id_setor: string;
      }[];

      return regions.map((item) => ({
        ...item,
        text_regiao: `${numberPadding(item.empresa_id)} - ${item.regiao_nome}`,
        text_setor: `${numberPadding(item.id_setor)} - ${item.nome_setor}`,
      }));
    },

    cRegions(): {
      id_usuario_empresa: string;
      empresa_id: string;
      regiao_nome: string;
      nome_setor: string;
      id_setor: string;
      text_regiao: string;
      text_setor: string;
    }[] {
      return this.regions.filter((r) => r.id_setor == this.selectSetor);
    },

    availableRegions(): {
      id_usuario_empresa: string;
      empresa_id: string;
      regiao_nome: string;
      nome_setor: string;
      id_setor: string;
      text_regiao: string;
      text_setor: string;
    }[] {
      // Filtra as regiões já selecionadas (updatedRegions) e garante que as regiões encontradas não sejam undefined
      const selectedRegions = this.updatedRegions
        .map((region) => {
          const [id_usuario_empresa] = region.split("-");
          const foundRegion = this.regions.find(
            (r) => r.id_usuario_empresa === id_usuario_empresa
          );
          return foundRegion ? foundRegion : null; // Retorna null se não encontrar a região
        })
        .filter((r): r is NonNullable<typeof r> => r !== null); // Garante que r não seja null ou undefined

      // Combina as regiões selecionadas com as novas regiões do setor (cRegions)
      const uniqueRegions = [...selectedRegions, ...this.cRegions].filter(
        (value, index, self) =>
          index ===
          self.findIndex(
            (r) => r.id_usuario_empresa === value.id_usuario_empresa
          )
      );

      // Adiciona as regiões e setores vinculados da rota de pessoas_vinculos
      const personRegionsAndSectors = this.personVinculos.map((vinculo) => {
        const foundRegion = this.regions.find(
          (region) =>
            region.empresa_id === vinculo.id &&
            region.id_setor === vinculo.setor_id
        );
        return foundRegion || null; // Retorna null se não encontrar a região/ setor
      });

      const validPersonRegionsAndSectors = personRegionsAndSectors.filter(
        (r): r is NonNullable<typeof r> => r !== null
      );

      // Combina todas as regiões
      const allRegions = [
        ...uniqueRegions,
        ...validPersonRegionsAndSectors,
      ].filter(
        (value, index, self) =>
          index ===
          self.findIndex(
            (r) => r.id_usuario_empresa === value.id_usuario_empresa
          )
      );

      return allRegions;
    },

    region(): string {
      const id = this.$store.state.region?.id ?? 0;
      const nome = this.$store.state.region?.nome_raz ?? "Região";
      return `${numberPadding(id)} - ${nome}`;
    },

    globalLoading: {
      get(): boolean {
        return this.$store.state.globalLoading;
      },
      set(value: boolean) {
        this.$store.commit("globalLoading", value);
      },
    },

    setores(): { id: string; text_setor: string }[] {
      return this.regions.map((r) => ({
        id: r.id_setor,
        text_setor: r.text_setor,
      }));
    },
  },

  mounted() {
    this.getTransactioners().then();
  },

  methods: {
    cpf,
    cnpj,
    maxLength,
    required,
    arrayRequired,

    async evOnSubmit() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("pessoas.write");

        const tblPeople = new Table("pessoas");

        const obj = {
          ...this.dataPerson,
          id: this.dataPerson.id || "default",
          doc_ie:
            this.dataPerson.tipo === this.PESSOA_JURIDICA
              ? this.dataPerson.doc_ie
              : "",
        };
        tblPeople.setColsFromObject(obj);
        tblPeople.addRowsFromObject(obj);

        const tblTransactioners = new Table("transacionadores");
        const data = { transacionadores: this.updatedTransactioners.join(",") };
        tblTransactioners.setColsFromObject(data);
        tblTransactioners.addRowsFromObject(data);

        const tblRegions = new Table("vinculos");
        const dataRegions = { regioes: this.sendRegionsVinculos.join(",") };
        tblRegions.setColsFromObject(dataRegions);
        tblRegions.addRowsFromObject(dataRegions);
        rq.addTables(tblPeople, tblTransactioners, tblRegions);

        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;

          const errorName = WorgError.getErrorByCode(this.ret_id).name;
          if (errorName === "VldError") this.form.validate();
          else if (errorName === "ErrDupEntry")
            this.ret_msg = "Já existe uma pessoa com esse nome";

          return;
        }

        this.ret_msg = `Pessoa ${
          this.dataPerson.id ? "atualizada" : "cadastrada"
        } com sucesso`;

        this.dataPerson.id = rsp.ret_id.toString();

        this.$emit("updated");
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    clearForm() {
      this.dataPerson = {
        id: "",
        referente_id: "default",
        empresa_id: "default",
        nome_raz: "",
        apelido_fant: "",
        cpf_cnpj: "",
        doc_ie: "",
        tipo: "",
        inf_adicional: "",
        data_criacao: "default",
        desativado: "0",
      };
      this.updatedTransactioners = [];

      this.form.resetValidation();
    },

    async getTransactioners() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("transacionadores.read");
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.transactioners =
          rsp.getTable("transacionadores")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async getPersonTransactioners() {
      try {
        if (!this.dataPerson.id) {
          this.personTransactioners = [];
          return;
        }

        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("pessoas_transacionadores.read", {
          pessoaId: this.dataPerson.id,
        });
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.personTransactioners =
          rsp.getTable("pessoas_transacionadores")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async getPersonVinculos() {
      try {
        if (!this.dataPerson.id) {
          this.availableRegions = [];
          return;
        }

        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("pessoas_vinculos.read", {
          pessoaId: this.dataPerson.id,
        });
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.personVinculos = rsp.getTable("pessoas")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
